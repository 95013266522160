import { ReactNode } from "react";

import { classNames } from "@/utils/styling-utils";
import { TextTheme } from "../ui/ui.model";

interface HeadingProps {
  children: ReactNode;
  type: "h1" | "h2" | "h3";
  uppercase?: boolean;
  color?: TextTheme;
}

const cssClasses = (property: string, value?: string) => {
  if (!value) {
    return;
  }
  const className = `heading__${property}--${value}`;
  return className;
};

export const Heading = ({ children, type, uppercase, color }: HeadingProps) => {
  const cssColorClass = cssClasses("color", color);

  return (
    <div className={classNames(cssColorClass, "heading", uppercase && "heading--uppercase")}>
      {type === "h1" && <h1>{children}</h1>}
      {type === "h2" && <h2>{children}</h2>}
      {type === "h3" && <h3>{children}</h3>}
    </div>
  );
};
