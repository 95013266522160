import { classNames } from "@/utils/styling-utils";
import { ReactNode } from "react";
import { WidthType } from "../layout/layout.model";
import { ButtonSizeType, ButtonType } from "./button";

// Nedenstående brukes av Button, LinkButton og FakeButton
export interface ButtonVisibleAreaProps {
  buttonTypeSuffix: string;
  children: ReactNode;
  size?: ButtonSizeType;
  width?: WidthType;
  wrap?: boolean;
  buttonType?: ButtonType;
}

export const ButtonVisibleArea = ({ buttonTypeSuffix, children, size, width, wrap }: ButtonVisibleAreaProps) => (
  <div
    className={classNames(
      "button__visible",
      buttonTypeSuffix && `button__visible${buttonTypeSuffix}__visible`,
      size && `button__visible--size--${size}`,
      width && `button--width--${width}`,
      wrap && `button__visible--wrap`
    )}
  >
    {children}
  </div>
);
