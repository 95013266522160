"use client";

import React, { ReactNode, SyntheticEvent } from "react";
import { classNames } from "@/utils/styling-utils";

import { Spinner } from "../ui/spinner";
import { getButtonTypeSuffix } from "./button-utils";
import { WidthType } from "../layout/layout.model";
import { ButtonVisibleArea } from "./button-visible-area";

export type ButtonType = "primary" | "secondary" | "tertiary" | "neutral" | "primary-light" | "secondary-light";
export type ButtonSizeType = "big";
export type ButtonFunction = "submit" | "reset" | "button";

export interface ButtonProps {
  ariaLabel?: string;
  buttonType?: ButtonType;
  children: ReactNode;
  disabled?: boolean;
  loading?: boolean;
  onClick?: (e: SyntheticEvent) => void;
  size?: ButtonSizeType;
  type?: ButtonFunction;
  width?: WidthType;
  wrap?: boolean;
}

export const Button = ({
  ariaLabel,
  buttonType,
  children,
  disabled,
  loading,
  onClick,
  size,
  type,
  width,
  wrap
}: ButtonProps) => {
  const buttonTypeSuffix = getButtonTypeSuffix(buttonType);

  return (
    <button
      aria-label={ariaLabel}
      className={classNames(
        "button",
        buttonTypeSuffix && `button${buttonTypeSuffix}`,
        disabled && "button--disabled",
        size && `button--size--${size}`,
        width && `button--width--${width}`
      )}
      disabled={disabled}
      onClick={onClick}
      type={type}
    >
      <ButtonVisibleArea buttonTypeSuffix={buttonTypeSuffix} size={size} width={width} wrap={wrap}>
        {loading && <Spinner />}
        {children}
      </ButtonVisibleArea>
    </button>
  );
};
